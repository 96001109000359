<template>
  <b-container>
    <div class=" flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
      <b-row class="justify-content-center">     
        <b-col md="8" sm="10" class="card-group-login">                     
        <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit.prevent>
                    <h2 class="text-black">{{ $t('login.title') }}</h2>
                    <p class="text-muted"> {{ $t('login.subtitle') }}</p>
                    <b-row v-if="error">
                      <b-col cols="12">
                          <div class="alert alert-danger" role="alert" v-for="error_message in errors">
                              {{error_message}}
                          </div>
                      </b-col>
                    </b-row>
                 
                  <CInput 
                    placeholder="Email" 
                    v-model="email">
                      <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <!-- <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" class="form-control" placeholder="Email" autocomplete="username email" v-model="email"/>
                  </b-input-group> -->
                  <!-- <b-input-group class="mb-4">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="password" class="form-control"
                                  placeholder="Contraseña" autocomplete="current-password" v-model="password"/>
                  </b-input-group> -->

                  <CInput 
                    placeholder="Contraseña" 
                    v-model="password" 
                    type="password">
                    <template #prepend-content>
                      <CIcon name="cil-shield-alt"/>
                    </template>
                  </CInput>

                  <b-row>
                    <b-col cols="12">
                      <b-button type="submit" class="btn btn-success mb-3 btn-login-green" block v-on:click="login">
                          <span>{{ $t('login.sign_in_button') }}</span>
                      </b-button>
                    </b-col>
                  </b-row>                      
                  
                  <strong>Inicia sesión con</strong>
                  <p></p>
                  <b-row>                    
                    <!-- <b-col cols="12">
                      <b-button block variant="outline-light"  class="btn button-login" v-on:click="loginGoogle" >
                          <img src="@/assets/images/google-icon.svg" width="20" height="20" alt="icon-google" class="icon-google"/>
                          <span class="google-text">   Google</span>
                      </b-button>
                    </b-col> -->
                    <b-col lg="12" style="margin-bottom:1rem;">                      
                      <CButton 
                        block 
                        color="facebook" 
                        @click="loginFacebook"
                        >
                        <CIcon name="cib-facebook"/>
                          Facebook
                      </CButton>             
                    </b-col>
                    <p></p>
                    <b-col lg="12">
                      <CButton 
                        block 
                        @click="loginGoogle" 
                        >
                          <img src="@/assets/images/google-icon.svg" width="20" height="20" alt="icon-google" class="icon-google"/>
                          <span class="google-text">   Google</span>
                      </CButton>                
                    </b-col>
                  </b-row>                  
                <br>
                  <b-row >
                    <b-col class="pass">
                      <button type="button" class="btn btn-link text-link-color" @click.prevent="passRecovery">¿Olvidaste tu constraseña?</button>
                    </b-col>
                  </b-row>

                </b-form>
              </b-card-body>
            </b-card>
            <b-card no-body class="p-bg py-5 panel-signup">
              <b-card-body class="text-center">
                <div>
                  <h2 class="p-bg-text">{{$t('login.sign_up_title')}}</h2>
                  <p class="p-bg-text">Conviértete en nuevo usuario de Go Guau</p>                  
                  <button type="button" class="btn btn-link p-bg-text" @click.prevent="visitSignUp">
                    {{$t('login.sign_up_subtitle')}}
                  </button>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>

      </b-row> 
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Login',
  data: function() {
    return {
      email: '',
      password: '',
      error: false,
      redirect: null,
      errors: [],         
    }
  },
  created(){
    let query = this.$route.query
    console.log(query)
    if('redirect' in this.$route.query) {
      let query = this.$route.query      
      if(query.redirect == 'serviceQualification') {
        this.redirect = {
          redirect: 'serviceQualification',
          id: query.id,          
        }       
      } else if( query.redirect == 'serviceGPS'){
        console.log('asfdsd')
        this.redirect = {
          redirect: 'serviceGPS',
          idObjeto: query.idObjeto,
          fecha_i: query.fecha_i
        }
      }
    }
  }, 
  methods: {
    login: async function() {
        this.error = false
        this.errors = []
       
        try{
              let response = await this.$firebase.auth().signInWithEmailAndPassword(this.email, this.password)
              if ('user' in response){
                var user = response.user;
                var token = await user.getIdToken()
                var userId = user.uid                
                this.$store.commit('defUserId', userId)
                let userIdBd = this.$store.getters.userId;                
                this.redirectLogin()
              } else { 
                this.error = true
                this.errors.push(this.$t('login.errorMessages.couldNotInitSession'))
              }
        } catch (error){
            this.error = true
            if (error != null && 'code' in error){
              if (error.code == 'auth/invalid-email'){
                  this.errors.push(this.$t('login.errorMessages.invalidEmail'))
              } else if (error.code == 'auth/wrong-password'){
                  this.errors.push(this.$t('login.errorMessages.invalidPassword'))
              }
              else {
                  this.errors.push(this.$t('login.errorMessages.couldNotInitSession'))
              }
            } else{ 
              this.errors.push(this.$t('login.errorMessages.couldNotInitSession'))
            }
        }
    },
    loginFacebook: function(){
        console.log(this.$router)
        const facebook_provider = new this.$firebase.auth.FacebookAuthProvider()
        this.providerLogin(facebook_provider)
    },
    loginGoogle: function(){
        const  google_provider = new this.$firebase.auth.GoogleAuthProvider()
        this.providerLogin(google_provider)
    },
    providerLogin: async function(provider){
        this.error = false
        try {
            let response = await this.$firebase.auth().signInWithPopup(provider)
            if ('user' in response){
              var user = response.user;
              var userId = user.uid
              this.$store.commit('defUserId', userId)
              this.redirectLogin()
            } else { 
              this.error = true
              this.errors.push(this.$t('login.errorMessages.couldNotInitSession'))
            }
        } catch (error){
          console.log(error)
          this.error = true
          this.errors = [this.$t('login.errorMessages.couldNotInitSession')]
        }
    },
    redirectLogin: function(){        
      if (this.redirect){
        if (this.redirect.redirect == 'project'){
          this.$router.replace('/projects/project/' + this.redirect.id)
        } else if(this.redirect.redirect == 'expert'){
          this.$router.replace('/users/profile/' + this.redirect.id)
        } else if(this.redirect.redirect == 'service'){
          this.$router.replace('/services/profile/' + this.redirect.id)
        } else if(this.redirect.redirect == 'serviceQualification') {
          this.$router.push({
            name: 'service-qualification', 
            query: {id: this.redirect.id}
          })
        } else if(this.redirect.redirect == 'serviceGPS'){
          this.$router.push({
            name: 'Seguimiento', 
            query: {
              idObjeto: this.redirect.idObjeto, 
              fecha_i: this.redirect.fecha_i
            }
          })
        }else {
          this.$router.replace('/dashboard')
        }
      } else {
        this.$router.replace('/dashboard')
      }
    },
    visitSignUp: function(){
      if (this.redirect){          
        this.$router.push({ path: '/pages/register', query: this.redirect})          
      } else { 
        this.$router.replace('/pages/register')
      }
    },
    passRecovery(){
      this.$router.replace('/pages/passrecovery')
    },
  }
}
</script>

<style scoped>
    .pass{
      text-align: center;
    }
    .text-black{
      color: black;
    }

    .logo-login{
      max-width: 10%;
    }

    .card-group-login{
      margin-top: 3rem;
    }

    @media (max-width: 360px) {
      .title {
        font-size: 1.5rem;
      }
    }

</style>
